var s = Object.defineProperty;
var u = (a, e, r) => e in a ? s(a, e, { enumerable: !0, configurable: !0, writable: !0, value: r }) : a[e] = r;
var i = (a, e, r) => (u(a, typeof e != "symbol" ? e + "" : e, r), r);
class c {
  /**
   * @param {Object} options
   * @param {Boolean|undefined} [options.standalone] Set to true if you are using your own external RAF loop.
   * @param {Number|undefined} [options.triggerArea] The size of the area around the elements to trigger the magnetic effect.
   * @param {Number|undefined} [options.interpolationFactor] The interpolation factor used by the lerp function.
   * @param {Number|undefined} [options.magneticForce] The strength of the magnetic effect.
   * @param {Boolean|undefined} [options.useAbsoluteMousePosition] Use mouse position relative to the client.
   */
  constructor(e) {
    i(this, "validateOptions", () => {
      if (typeof this.standalone != "boolean")
        throw new Error("standalone value must be a boolean");
      if (typeof this.triggerArea != "number")
        throw new Error("triggerArea valu muast be a numeric value greater or equal to 0.");
      if (this.interpolationFactor < 0)
        throw new Error("interpolationFactor value must be greater or equal than 0.");
      if (typeof this.interpolationFactor != "number")
        throw new Error("interpolationFactor value must be a numeric value between 0 and 1.");
      if (this.interpolationFactor <= 0)
        throw new Error("interpolationFactor value must be greater than 0.");
      if (this.interpolationFactor > 1)
        throw new Error("interpolationFactor value must be less than 1.");
      if (typeof this.magneticForce != "number")
        throw new Error("magneticForce value must be a numeric value between 0 and 1.");
      if (this.magneticForce <= 0)
        throw new Error("magneticForce value must be greater than 0.");
      if (this.magneticForce > 1)
        throw new Error("magneticForce value must be less than 1.");
      if (typeof this.useAbsoluteMousePosition != "boolean")
        throw new Error("useAbsoluteMousePosition value must be a boolean");
    });
    i(this, "lerp", (e, r, t, o) => {
      const n = 1 - Math.pow(t, o / 100);
      return e * (1 - n) + r * n;
    });
    i(this, "initEvents", () => {
      window.addEventListener("mousemove", this.onMouseMove), window.addEventListener("resize", this.onResize);
    });
    i(this, "destroy", () => {
      window.removeEventListener("mousemove", this.onMouseMove), window.removeEventListener("resize", this.onResize);
    });
    i(this, "onMouseMove", (e) => {
      if (this.useAbsoluteMousePosition) {
        this.mouse.x = e.clientX, this.mouse.y = e.clientY;
        return;
      }
      this.mouse.x = e.pageX, this.mouse.y = e.pageY;
    });
    i(this, "onResize", () => {
      this.lerpingData = [];
      for (let e = 0; e < this.magneticElements.length; e++)
        this.lerpingData.push({
          boundingClientRect: this.magneticElements[e].getBoundingClientRect(),
          x: {
            current: 0,
            target: 0
          },
          y: {
            current: 0,
            target: 0
          }
        });
    });
    i(this, "calculateDistance", (e, r, t, o) => Math.hypot(e - t, r - o));
    i(this, "update", () => {
      const e = performance.now() - this.time;
      this.time = performance.now();
      for (let r = 0; r < this.lerpingData.length; r++) {
        const t = this.lerpingData[r], o = this.calculateDistance(
          this.mouse.x,
          this.mouse.y,
          t.boundingClientRect.left + t.boundingClientRect.width / 2,
          t.boundingClientRect.top + t.boundingClientRect.height / 2
        ), n = { x: 0, y: 0 };
        o < this.triggerArea && (n.x = this.mouse.x - (t.boundingClientRect.left + t.boundingClientRect.width / 2), n.y = this.mouse.y - (t.boundingClientRect.top + t.boundingClientRect.height / 2)), t.x.target = n.x * this.magneticForce, t.y.target = n.y * this.magneticForce, Math.abs(t.x.current) < 0.01 && t.x.target == 0 && (t.x.current = 0), Math.abs(t.y.current) < 0.01 && t.y.target == 0 && (t.y.current = 0), t.x.current = this.lerp(t.x.current, t.x.target, this.interpolationFactor, e), t.y.current = this.lerp(t.y.current, t.y.target, this.interpolationFactor, e), this.magneticElements[r].style.transform = `translate(${t.x.current}px,${t.y.current}px)`;
      }
      this.standalone || requestAnimationFrame(this.update);
    });
    this.standalone = (e == null ? void 0 : e.standalone) ?? !1, this.triggerArea = (e == null ? void 0 : e.triggerArea) ?? 200, this.interpolationFactor = (e == null ? void 0 : e.interpolationFactor) ?? 0.8, this.magneticForce = (e == null ? void 0 : e.magneticForce) ?? 0.3, this.useAbsoluteMousePosition = e.useAbsoluteMousePosition ?? !1, this.validateOptions(e), this.initEvents(), this.mouse = {
      x: 0,
      y: 0
    }, this.magneticElements = document.querySelectorAll("[magnetic-element]"), this.onResize(), this.time = performance.now(), this.standalone || this.update();
  }
}
export {
  c as MagneticElementsController
};
